<template>

  <carousel :loop="false" :autoplay="false" :margin="0" :dots="false" :nav="true"
     :navText="[]" :responsive="responsive" class="pricingtable-carousel owl-rtl" dir="ltr">
    <div class="pricingtable_box" v-for="(item, inx) in orderedFlight.pricingInformation" :key="inx"
      :class="{'active': inx===value, 'disable': noSelect}" @click="() => selectThis(inx)">
      <pricing-one-box :isHeader="inx===0" :data="item" :rightPlaceList="commercials"
      :diffPrice="item.fare.totalFare.totalPrice-orderedFlight.pricingInformation[0].fare.totalFare.totalPrice"/>
    </div>
  </carousel>

</template>

<script>

import carousel from 'vue-owl-carousel';
import { mapGetters } from 'vuex';

export default {
  name: 'pricing-carousel',
  components: {
    carousel,
    PricingOneBox: () => import('./pricingTableOneBox'),
  },
  props: {
    value: {
      type: Number,
      default: -1,
    },
    commercials: {
      type: Object,
      default: null,
    },
    noSelect: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      responsive: {
        0: {
          items: 1.1,
          dots: true,
        },
        480: {
          items: 1.5,
          dots: true,
        },
        640: {
          items: 2.5,
          dots: true,
        },
        768: {
          items: 2.5,
          dots: true,
        },
        992: {
          items: 3,
        },
        1199: {
          items: 3,
        },
      },
      myOptions: {
        loop: true,
        autoplay: false,
        rtl: true,
        margin: 0,
        dots: false,
        nav: true,
        navText: [],
        items: 3,
        responsive: {
          0: {
            items: 1.1,
            dots: true,
          },
          480: {
            items: 1.5,
            dots: true,
          },
          640: {
            items: 2.5,
            dots: true,
          },
          768: {
            items: 2.5,
            dots: true,
          },
          992: {
            items: 3,
          },
          1199: {
            items: 3,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
    }),
  },
  methods: {
    selectThis(index) {
      if (this.noSelect) return;
      this.$emit('change', index);
    },
  },
};
</script>

<style>
  .pricingtable-carousel.owl-rtl .owl-nav {
    direction: rtl;
  }
  .pricingtable-carousel .owl-theme .owl-nav .owl-prev,
  .pricingtable-carousel .owl-theme .owl-nav .owl-next {
    border-radius: 50%;
  }
  .pricingtable-carousel .owl-theme .owl-dots .owl-dot span {
    display: none;
  }
  .pricingtable_box.disable {
    opacity: 0.6;
  }
</style>
